import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
  state: () => {
    return {
      userName: 'Vasili Savitski',
      email: 'vasili@gmail.com',
      memberSince: '8/12/2020',
      pfp: 'https://picsum.photos/id/22/200/300',
      is2FAEnabled: false,
      token: null,
      logedIn: false,
    }
  },

  actions: {
    toggle2FA() {
      this.is2FAEnabled = !this.is2FAEnabled
    },

    changeUserName(userName: string) {
      this.userName = userName
    },

    changeLogin() {
      this.logedIn = true
    },

    revokeSession() {
      localStorage.clear()
      this.token = null
      this.logedIn = false

      return
    },
    validSession() {
      if(localStorage.getItem('token') == null) {
        return false
      }

      if(this.token == null) {
        this.token = localStorage.getItem('token')
        this.logedIn = true
      }

      return true
    },

    setToken( token ) {
      this.token = token
      localStorage.setItem('token', token);
    },
  },
})
