<template>
  <svg
    id="Layer_1"
    :height="height"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 868 160"
    style="enable-background: new 0 0 868 160"
    xml:space="preserve"
  >
    <path
      class="stL0"
      d="M349.2,50.1c-2.8-1.5-5.6-3.1-8.4-4.6c-2.6-1.3-5-2.8-7.5-4.2c-1-0.8-1.9-2-3.2-0.5c-1.5,1.1-3.1,2.1-4.5,3.2
	c-1.8,1.2-3.5,2.5-5.4,3.5c-0.3,0.2-0.6,0.5-0.9,0.7c-0.7,0.3-1.3,0.9-2.1,1.3l0,0c-0.7,0.8-1.6,1.2-2.4,1.7l0,0
	c-2.7,2-5.4,4-8.2,5.7c-0.4,0.3-0.8,0.5-1.2,0.8c-1.1,1.1-2.4,1.7-3.7,2.5c-2.8,1.9-5.6,3.6-8.2,5.7c-1,0.7-1.7,2-3.2,1.9
	c-1.6-1.3-2.4-3.2-3.9-4.7c-0.1-0.3-0.2-0.6-0.3-0.8c-1-1.9,0.3-3,1.4-4.2c0.3-0.3,0.6-0.5,1-0.7c2.3-1,4.5-2.4,6.3-4.2
	c0.3-0.4,0.8-0.7,1.3-1.1c0.9-0.8,1.8-1.5,3-2c0.2-0.3,0.5-0.5,0.7-0.8c1.6-0.9,3-2,4.6-2.9c1.2-0.8,2.4-1.6,3.6-2.5
	c0.9-0.8,2-1.3,2.8-2.1c0.9-0.7,1.9-1.4,2.9-2c0.1-0.1,0.3-0.3,0.4-0.4c1.9-1.2,3.9-2.4,5.7-3.8c2.8-1.8,5.3-4.1,8.3-5.6l0,0
	c0.5-0.4,0.9-0.8,1.4-1.1c1.6-1,2.8-0.1,4.1,0.7c2.5,1.4,4.9,3.1,7.5,4.3c4.6,2.9,9.3,5.3,13.9,8.1c0.8,0.9,2,1.1,2.8,1.9l0,0
	c1.5,0.6,2.9,1.4,4.1,2.4l0,0c0.6,0.3,1.2,0.4,1.6,1c1.6,1.2,3.4,2.2,5.1,3.1c0.2,0.1,0.3,0.2,0.5,0.3c0.4,0.5,0.9,0.8,1.6,0.9
	c0.2,0.1,0.3,0.1,0.5,0.2c0.6,0.4,1.2,0.8,1.9,1.1c0.5,0.3,1,0.4,1.5,0.5c0.8,0.2,1.3,1,2.1,1.3c0.4,0.2,0.6,0.5,0.8,0.8
	c1.3,1.3,1.2,2.6,0,3.9c-1.6,1.6-2.6,6.1-5.5,4c-1.8-1.2-3.7-2.3-5.5-3.4c-1.3-1-2.9-1.7-4.4-2.3c-0.2-0.1-0.3-0.2-0.5-0.3
	c-1.4-1.3-3-2.2-4.7-2.9c-0.3,0.1-0.6,0-0.7-0.3c-1.2-1.2-2.7-1.9-4.1-2.5l0,0C351.3,50.9,350.1,50.6,349.2,50.1z"
    />
    <path
      class="stL1"
      d="M407.9,118.6c-7.4-0.2-14.6-1.7-20.9-6.7c-12.1-9.7-12.1-26.7,0.2-36.2c7.8-6.1,16.9-7.4,26.5-6.4
	c7,0.8,13.3,3.3,18.4,8.3c1.2,1.1,0.9,1.6-0.3,2.4c-2.8,1.7-2.8,1.8-5.5-0.4c-9.9-8-26.1-7.8-35.8,0.4c-8.3,7.1-8.6,19.3-0.8,26.9
	c6.5,6.3,14.3,7.8,22.9,6.7c5.7-0.7,10.9-2.7,15.2-6.7c1.1-1,1.7-0.2,2.5,0.2c3.3,2,3.3,2,0.2,4.4
	C424.1,116.9,416.4,118.6,407.9,118.6z"
    />
    <path
      class="stL2"
      d="M675,117.1l-0.2,0l-0.1,0.1c-3,1.3-6.2,1.4-9.4,1.8c-8.5,0.2-16.6-1.2-23.7-6.3c-1-1.1-2.2-2-3.2-3.1
	c-2.8-2.9-4.6-6.5-5.4-10.4c-0.7-3.6-0.6-7.2,0-10.9c0.2-1.8,1-3.4,1.7-5c1.6-2.7,3.7-4.9,6-7c1.1-0.6,2-1.4,3-2.2
	c2-1.4,4.2-2.4,6.6-3l0,0c1.8-0.7,3.6-1.3,5.6-1.5c5.4-0.6,10.7-0.7,16.1,0.4c4.6,0.8,8.7,2.6,12.4,5.4c0.4,0.3,0.7,0.7,1.2,0.8
	c0.2,0.2,0.4,0.3,0.6,0.5c2.4,2.3,2.4,2.5-0.5,4.2c-1.4,0.8-2.7,1.4-3.8-0.4c-0.9-0.7-1.9-1.4-2.8-2.1c-1.4-0.5-2.5-1.6-4-1.9
	c-2.8-1.1-5.7-1.7-8.7-2.2c-2.5-0.5-4.9-0.3-7.4,0.1c-2.9,1-6,1.3-8.8,2.9c-1.5,0.9-2.9,1.9-4.4,2.7c-6.6,4.6-8.9,14.5-5.2,21.7
	c1,2,2.6,3.7,4,5.5c5.4,5.2,11.9,7.2,19.3,6.9c2.8-0.5,5.6-0.6,8.4-1.4c3.8-1,6.9-3,10-5.4c1.4-1.1,5.1,0.2,5.5,1.9
	c0.2,0.7-0.3,1.2-0.7,1.6C683.4,113.8,679.5,116,675,117.1z"
    />
    <path
      class="stL2"
      d="M569.1,73.3c0.3,1.3-0.3,1.7-1.5,1.7c-2.9,0-5.7,0.1-8.6,0c-1.8-0.1-2.3,0.4-2.3,2.3c0.1,12.5,0.1,25.1,0,37.6
	c0,0.9,0.5,2.2-0.7,2.5c-1.6,0.3-3.3,0.5-4.8-0.1c-0.7-0.3-0.4-1.2-0.4-1.9c0-12.7,0-25.5,0-38.2c0-0.7-0.1-1.4-0.2-2
	c-3.2-0.6-6.5-0.2-9.7-0.2c-1.5,0-2.3-0.5-2.3-2.1c0-1.6,0.6-2.2,2.2-2.2c3.5,0,7.1,0,10.6,0c4.6,0,9.3,0,13.9,0
	C568.5,70.5,568.5,70.5,569.1,73.3z"
    />
    <path
      class="stL3"
      d="M517.5,93.8c0-7.2,0-14.3,0-21.5c0-1.5,0.3-2.1,2-2.1c3.8,0.1,3.8,0,3.8,3.7c0,13.8,0,27.5,0,41.3
	c0,1.8-0.5,2.3-2.3,2.3c-3.5-0.1-3.5,0-3.5-3.6C517.5,107.2,517.5,100.5,517.5,93.8z"
    />
    <path
      class="stL4"
      d="M585.2,93.6c0-7,0-14,0-21.1c0-1.7,0.4-2.4,2.3-2.4c9.3,0.1,18.7,0.1,28,0c2.1,0,2.2,0.9,2.3,2.6
	c0.1,1.8-0.5,2.4-2.3,2.3c-7.4-0.1-14.9,0-22.3-0.1c-1.6,0-2.1,0.4-2.1,2c0.1,3.4,0.1,6.8,0,10.2c-0.1,1.7,0.4,2.4,2.2,2.3
	c7.3-0.1,14.6-0.1,21.9,0c0.7,0,1.9-0.4,2.1,0.7c0.2,1.2,0.4,2.5-0.1,3.6c-0.4,0.7-1.4,0.4-2.1,0.4c-7.2,0-14.5,0.1-21.7,0
	c-1.8,0-2.4,0.4-2.3,2.3c0.1,4.6,0.1,9.1,0,13.7c-0.1,1.9,0.5,2.6,2.5,2.5c7.2-0.1,14.5-0.1,21.7,0c0.7,0,1.9-0.3,2.1,0.8
	c0.2,1.2,0.4,2.5-0.2,3.6c-0.3,0.6-1,0.4-1.5,0.4c-9.5,0-19.1,0-28.6,0c-2,0-1.8-1.2-1.8-2.4C585.2,107.9,585.2,100.8,585.2,93.6z"
    />
    <path
      class="stL5"
      d="M519.9,67.4c-3.1,0-5.6-2.1-5.6-4.7c0-2.5,2.6-4.8,5.6-4.8c3,0,5.8,2.3,5.8,4.9C525.6,65.3,523,67.4,519.9,67.4
	z"
    />
    <path
      class="stL6"
      d="M569.1,73.3c-0.3-2.5-2.1-2.5-4.1-2.5c-8,0.1-15.9,0-23.9,0c-1.2,0-2.4-0.2-2.4,1.7c0,1.6,1,2,2.4,2
	c2.5,0,5,0,7.6,0c0.6,0,1.4-0.3,1.9,0.5c-3.5,0-7.1-0.1-10.6,0c-1.6,0-1.8-0.8-1.7-2.1c0.1-1.1-0.7-2.8,1.6-2.8c9.3,0,18.7-0.1,28,0
	C570.3,70.3,568.9,72.2,569.1,73.3z"
    />
    <path
      class="stL7"
      d="M350.9,95.4c1.5,0.3,2.3,1.4,2.9,2.6c2.6,5.1,3.4,10.4,2.6,16.1c-0.4,3.1-1.8,5.5-3.3,8.1
	c-0.2,0.4-0.5,0.6-1,0.5c2.8-4.3,4.4-8.9,4.1-14c-0.2-3.4-0.9-6.7-2.8-9.7C352.5,97.9,351.3,96.9,350.9,95.4z"
    />
    <path
      class="stL8"
      d="M306.5,59.8c2,1.1,4.2,1.9,6,3.4c0.9,0.7,1.6,0.6,2.2-0.3c1.1-1.5,1.9-3.2,3.2-4.6c1.1-1.2-0.6-1.7-0.7-2.6
	c2.3,0.4,2.6,0.9,1.6,2.8c-0.6,1.3-1.7,2.2-2.4,3.5c-0.2,0.3-0.4,0.6-0.6,0.8c-1.5,2.3-1.9,2.4-4.1,0.9c-1.2-0.8-2.5-1.3-3.6-2.2
	c-0.7-0.5-1.4-1-2.2-1.5C306.1,59.7,306.3,59.6,306.5,59.8z"
    />
    <g>
      <path class="stL7" d="M366.5,108.1C366.5,108.2,366.5,108.2,366.5,108.1L366.5,108.1z" />
      <path
        class="stL7"
        d="M366,76.7c-1.3-3.5-12-20.3-14.1-12c-2,7.2-2.6,5.8-8,2.2c-7.9-5.6-23.4-2.2-20.3,9.8
		c10.2,16.2,46.2,15.2,28.4,46.1l0,0C333.8,138.8,383.4,114,366,76.7z"
      />
    </g>
    <path class="stL9" d="M366.5,108.2c-0.1-2.4,1.2-4.5,2-6.6C368.3,103.9,367.7,106.1,366.5,108.2z" />
    <path class="stL9" d="M364.8,111.8c0.2-1.4,0.5-2.7,1.7-3.7C366.2,109.5,365.7,110.7,364.8,111.8z" />
    <path class="stL10" d="M289.2,50.1c1.2,0.2,2.3,0.5,2.9,1.7C291,51.4,290,50.9,289.2,50.1z" />
    <path
      class="stL1"
      d="M340.2,102.1c-0.9-0.6-1.9-1.1-2.8-1.7c-4.8-2.7-9.9-4.9-14.4-8.1c-3.2-2.3-6-4.9-7.6-8.6
	C312,76,313.2,62.6,325.6,57c1.1-0.3,2.1-0.6,3.2-1l0,0c0.8-0.1,1.6-0.3,2.5-0.4c0.5,0.1,0.9,0.1,1.4-0.2c-1.2-0.5-2.3-0.8-3.5-0.2
	c0,0,0,0,0,0c-0.4,0.1-0.8,0.2-1.2,0.2c-0.1,0-0.2,0-0.4,0.1c-0.2,0-0.4,0.1-0.5,0.1c0,0,0,0,0,0c-1.7,0.2-3.4,0.4-4.9,1.3
	c-4.1,1.1-7.8,3.2-11.1,5.9c-11.2,9.2-15.9,21.1-14.2,35.5c1.3,10.4,6.3,18.9,14.3,25.5c1.1,0.9,1.7,1.1,2.3-0.4
	c0.8-1.9,2-3.7,2.8-5.7c0.7-1.6,1.2-1.9,2.7-0.7c4.4,3.7,9.4,5.6,15.2,5.3c5.5-0.3,9.5-3.3,10.9-8.2
	C346.7,109,345.2,105.4,340.2,102.1z"
    />
    <path
      class="stL7"
      d="M94.5,191.2c0.5-0.9,1.1-1.8,1.6-2.8c0.6-0.4,1.1-0.8,1.7-1.3C97.6,189.3,97.3,191.3,94.5,191.2z"
    />
    <path
      class="stL11"
      d="M500.7,115.3c-8-14.4-16.1-28.7-24.1-43.1c-0.4-0.8-0.6-1.9-1.9-1.9c-1.2,0.1-1.4,1.2-1.8,2
	c-6.1,10.7-12.2,21.4-18.2,32.1c-2.4,4.2-4.8,8.5-7.4,13.1c1.7,0,2.9-0.1,4.1,0c1.8,0.2,2.7-0.5,3.5-2c2.2-4.1,4.6-8,6.8-12.1
	c0.6-1,1.2-1.4,2.4-1.4c7,0,14,0.1,21.1,0c1.2,0,1.9,0.4,2.4,1.4c2,3.6,4.3,7.1,6.1,10.8c1.3,2.6,2.9,3.7,5.8,3.4
	C501.9,117.3,501.9,117.5,500.7,115.3z M465.3,97.1c3.2-5.6,6.2-10.9,9.4-16.6c3.2,5.6,6.2,11,9.4,16.6
	C477.7,97.1,471.7,97.1,465.3,97.1z"
    />
    <path
      class="stL12"
      d="M585.9,74.2c0-3.3,0-3.3,3.2-3.3c8.3,0,16.6,0,25,0c1.2,0,3-0.9,3,1.7c0,2.6-1.8,1.9-3.2,1.9
	c-7,0-14.1,0.1-21.1,0c-2,0-2.7,0.8-2.5,2.4c0.3,3.7,0.1,7.5,0.1,11.2c0,1.6,1,2,2.5,2c7.2,0,14.5,0,21.7,0c0.4,0.5,1.4,0.2,1.7,1
	c0.7,1.6-0.1,2.5-1.9,2.4c-6.8-0.3-13.5-0.1-20.3-0.1c-0.3,0-0.5,0.2-0.7,0.3c-1.5,0-2.9,0.1-2.9,2.2c0,5,0,10.1,0,15.1
	c0,1.1,0.3,1.9,1.2,2.3c1.2-0.1,2.5-0.5,3.7,0v0c2.9-0.2,5.8-0.2,8.7-0.2c3.5,0,7.1-0.1,10.6,0.1c1.5,0.1,2.1,0.7,2,2.1
	c0,0.1,0,0.3,0,0.4c-0.3,0.5-0.9,0.4-1.2,0.8c-9.5,0-19.1,0-28.7,0c-0.5-0.5-0.6-1.2-0.6-1.9c0.4-12.9,0.1-25.8,0.2-38.7
	C586.3,75.4,586.3,74.8,585.9,74.2z"
    />
    <path
      class="stL13"
      d="M585.9,74.2c0.9,0.4,0.8,1.2,0.8,1.9c0,12.2,0,24.4,0,36.7c0,1.3-0.8,2.6,0,4c-1.4-0.3-0.8-1.4-0.8-2.2
	C585.9,101.1,585.9,87.6,585.9,74.2z"
    />
    <path
      class="stL14"
      d="M593.2,93.8c0-0.6,0.4-0.7,1-0.7c6.2,0,12.4,0,18.6,0c0.7,0,1.3,0.2,2,0.4c0.4,0.1,0.9,0,1.1-0.4
	c0.4-0.8-0.4-0.4-0.6-0.6c-0.1-0.1-0.2-0.3-0.2-0.3c1.5-1.2-0.8-1.2-0.6-1.9c1.3-0.1,2.2-0.1,2.2,1.9c0,1.9-0.8,1.8-2.1,1.8
	C607.5,93.8,600.4,93.8,593.2,93.8z"
    />
    <path
      class="stL15"
      d="M743.8,169.4c-1.1-5.3-5.5-5-9.1-4.9c-13.2,0.3-26.5-1.2-39.7,0.8c-1.1,0.2-2.3,0.2-3.2-0.9
	c15.9-2.1,31.9-0.6,47.8-0.8C742.6,163.5,746.4,164.6,743.8,169.4z"
    />
    <path
      class="stL15"
      d="M691.8,164.4c-2.9,0.1-5.8-0.4-8.6,1.5c-1.1,0.8-0.4-0.9-0.4-1.5C685.8,162.8,688.8,162.8,691.8,164.4z"
    />
    <path class="stL15" d="M740.8,172.4c0.1-2,1.6-2,3-2C744.6,173.7,741.9,171.8,740.8,172.4z" />
    <rect x="209.7" y="25.8" class="stL16" width="222.9" height="98.5" />
    <text transform="matrix(1 0 0 1 209.675 68.4148)" class="stL17 st18">By</text>
  </svg>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useColors } from 'vuestic-ui'

const { getColor } = useColors()

const props = withDefaults(
  defineProps<{
    height?: number
    start?: string
    end?: string
  }>(),
  {
    height: 18,
    start: 'primary',
    end: undefined,
  },
)

const colorsComputed = computed(() => {
  return {
    start: getColor(props.start),
    end: getColor(props.end || props.start),
  }
})
</script>
<style scoped>
.stL0 {
  fill: #76963d;
}
.stL1 {
  fill: #1f160e;
}
.stL2 {
  fill: #77963d;
}
.stL3 {
  fill: #201710;
}
.stL4 {
  fill: #7d9840;
}
.stL5 {
  fill: #201811;
}
.stL6 {
  fill: #7d9740;
}
.stL7 {
  fill: #7a963f;
}
.stL8 {
  fill: #7e9641;
}
.stL9 {
  fill: #7e9741;
}
.stL10 {
  fill: #7c9640;
}
.stL11 {
  fill: #1f160f;
}
.stL12 {
  fill: #77973d;
}
.stL13 {
  fill: #77933c;
}
.stL14 {
  fill: #77943c;
}
.stL15 {
  fill: #7a913b;
}
.stL16 {
  fill: none;
}
.stL17 {
  font-family: 'MyriadPro-Regular';
}
.stL18 {
  font-size: 60px;
}
</style>
