<template>
<!-- Generator: Adobe Illustrator 24.3.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg width="50%"  version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 1310.9 433.5" enable-background="new 0 0 1310.9 433.5" xml:space="preserve">
<!-- <rect y="-4.3" stroke="#000000" stroke-width="0.6803" stroke-miterlimit="10" width="1317.9" height="437.8"/> -->
<g>
	<path fill="#ffffff" d="M-85.6-202.1c-181.9,0-363.9,0-545.8,0.2c-6,0-7.2-1.2-7.2-7.2c0.2-205.9,0.2-411.7,0-617.6
		c0-6,1.2-7.2,7.2-7.2c363.9,0.2,727.7,0.2,1091.6,0c6,0,7.2,1.2,7.2,7.2c-0.2,205.9-0.2,411.7,0,617.6c0,6-1.2,7.2-7.2,7.2
		C278.2-202.1,96.3-202.1-85.6-202.1z"/>
	<path d="M-435.6-781.4c3.1-9.6,6.6-18.8,18.2-21.3c2.1-0.5,2.8-2.7,4.1-4.3c5.8-7.3,12.8-9.1,20.7-4.7c3,1.7,5.6,2.3,9,1.9
		c13.3-1.5,22.4,6.1,23.9,19.5c0.3,2.8-0.3,5.7,2.6,7.8c11.3,8,11.9,19.4,9.1,31.3c-1,4.3-0.6,7.6,1.2,11.5c5.2,11.9,6.1,23.7-4,34
		c-1,1-1.6,2.4-2.2,3.7c-6.4,14.6-19.7,19.6-34.5,12.3c-1,13.7-0.1,26.7,3.3,39.7c1.3,2.4,0.5,4.3-2.1,4.2c-5.7-0.2-11.5,1.7-17-1.1
		c3.3-14.2,4.4-28.6,3.6-43.3c-12.1,3.9-30.5-0.6-37.1-8.8c-2.2-2.5-0.1-6.7-3.5-8.7c-2.2-3.5-1.8-7,0.2-10.5
		c2.4-3.5,3.2-7-0.3-10.4c-6.1-13.1-5.2-25.4,3.9-36.8C-434.3-770.7-432.8-775.9-435.6-781.4z"/>
	<path d="M325.7-732.3c-1-3.7-1.7-7.3,1.2-10.5c3.3-3.3,7.1-6,10.5-9.1c-1.2-9-3.2-18.3,8.1-22.9c-0.1-10.9,11-12.1,16.4-18.2
		c7.9-8.9,26.4-4.3,31.6,7.4c1.4,3.3,2.8,4.1,6.1,4.4c12.3,1.1,18.5,8.9,16.8,21.6c-0.6,4.9,0,7.6,4.8,9.4c2,0.7,4.8,2.9,4.9,4.5
		c0.4,9.8,5.9,18.1,7.3,27.4c1,6.7-5.9,10.1-8.6,15.1c-2.2,4,4,10.6,0.3,16.3c-0.5,0.7-0.8,2-1.4,2.1c-9.1,2.3-12.3,10.9-18.3,16.5
		c-3.1,2.9-7.6,3.4-11.6,1.9c-5.4-2-6,0.6-5.6,4.9c0.2,1.4,0.2,2.9,0.4,4.4c1.3,11.4,1.3,11.2-10.3,11.1c-4.9,0-7-1.2-6-6.5
		c0.6-2.8,0.2-5.8,0.4-8.7c0.2-3.7-0.5-5.9-5.1-6c-8.4-0.1-15.5-3.2-18.5-11.9c-1-2.9-3.5-2.7-5.5-3.5c-9.4-3.9-13-10.6-9.5-20.1
		c1.4-3.8,0-5.8-1.6-8.4c-1.4-3.6-1.3-7.2-0.1-10.8c-0.6-1.6-1.2-3.2-1.8-4.7C329.7-729.3,328.4-731.4,325.7-732.3z"/>
	<path d="M-270.4-548.2c5.6-3.4,9-5.9,0.1-10.2c-9.2-4.4-8.8-6.1,0-10.9c12.4-6.8,23.3-15.5,31.7-27.1c3.1-4.3,5-6.1,9.5-0.8
		c9,10.6,19.9,19.1,32.4,25.6c7.9,4.1,7.7,5.8-0.1,10.5c-2.7,1.7-5.6,3-8.9,4.8c5.7,6.9,12.7,10.3,21.2,10.8c4.7,1.6,2.2,4,0.3,5.6
		c-1.7,1.4-4.1,2.2-6,3.5c-4.2,1.9-8.3,3.9-12.2,7.4c8.7,6.8,19.1,7.4,29.2,10.3c-5.4,7.8-13.7,9.5-21.3,13.1
		c6.1,8.2,15.8,10.4,25,14.6c-9.1,9.6-21.1,10.5-31.8,14.4c1.4,3.4,5.3,3,7.4,6.3c-9.8,4.9-18.5,3.5-27.3-2.4
		c-1.5,6.1,0.8,11.4,2.3,16.4c2.4,7.6-0.7,9.1-7.4,9.2c-18.6,0.4-18.9,0.5-17.6-17.3c0.5-7.4-0.1-10.4-8.7-7.1
		c-7.8,3.1-16.3,0.8-25.7-4.6c5.4-1.8,11.2-1.3,15.7-6.4c-9.9-3.7-20.6-4.3-27.9-12.9c6.3-3.4,13.2-5.4,18.2-11.3
		c-5.1-3.1-10.1-6-14.9-9.2c-3.8-2.5-3.6-4.7,0.7-6.7c4.2-2,8.1-4.6,12.3-6.6c4.8-2.3,2.7-4.5,0.2-6.9
		C-278.3-541.7-273.1-544.6-270.4-548.2z"/>
	<path d="M86.1-236.7c-2.6-0.3-5.2-0.4-7.8-0.8c-5.3-0.8-9-2.1-4.8-8.8c3-4.7,3.3-10.5,2.8-16.1c-0.7-8-5.2-11.7-13.1-9.4
		c-12.1,3.5-20.9-1.3-27.3-10.5c-6.3-9.1-8.2-19.3-1.4-29.4c0.3-0.5,1.1-1,1-1.3c-2.3-12.9,4.8-22.9,10-33.6c1-2,17.8-30.1,19.9-31
		c3.3-1.4,3.3-4.5,4.7-6.9c4.6-8.1,14.3-9.1,20.1-1.9c2,2.5,3.9,3.9,6.9,4.6c1.8,0.4,4.8,2.1,4.8,3.2c0.2,11.1,12,15.7,14.3,25.5
		c0.2,0.8,0,2.2,0.5,2.5c10.4,7.2,13.6,16.7,10.3,28.9c-0.8,2.8,3,3.9,5,5.5c9.2,7.4,11.8,19,6.6,29.7c-4.2,8.5-15.8,13.2-26.4,10.6
		c-8.6-2.1-17.1,4.1-17.4,12.8c-0.2,5.6,0,11.1,2.5,16.3c3.8,7.9,3,9.1-6.1,9.9c-1.7,0.2-3.5,0-5.3,0
		C86.1-236.9,86.1-236.8,86.1-236.7z"/>
	<path d="M-356.7-350.1c0.1,1.3,0.4,2.5,2,2.8c3.6,1.4,3.6,4,2.1,6.9c-0.9,2.4,0.7,3.7,2,5.3c1.4,1.8,3.9,4,3.7,5.7
		c-1.9,11.1,4.7,21.7,1.6,32.5c-1,3.6,0.5,5.3,1.9,7.8c4.8,8.8,1,15.7-8.4,18.3c-8.7,2.4-16.6,7.6-26.5,3.7
		c-3.2-1.3-3.7,3.2-3.3,5.6c0.8,6,1.9,12.1,3.1,18c0.9,4.5-0.3,6.4-5.3,6.1c-6.6-0.4-14.7,3-19.6-0.9c-5.3-4.2,0.5-11.7,0.4-17.9
		c-0.1-7.2-0.6-12-9.7-10.5c-2.9,0.5-5.4-1.6-7.6-3.7c-1.2-1.2-2.8-3.2-4-3.1c-13.9,2.2-15.3-9.8-20.4-17.6c-1-1.6-1.8-4.4-1.1-5.8
		c4.2-7.9,1.1-18.2,4.8-24.5c5.6-9.6,7.1-20.7,13.6-29.5c1.9-2.5,2.5-5.1,2.8-8c0.3-3.6,1.1-8.3,4.1-9.2
		c9.8-2.9,10.4-15.9,21.1-17.6c9.1-1.4,15.5,4.6,22.2,8.2c7.8,4.2,14.3,11.7,19.4,19.5C-356.5-355.4-357.2-352.6-356.7-350.1z"/>
	<path d="M-557.8-292.9c-6.2,10.1-13.3,14.1-22.7,12.4c-9.8-1.8-14.2-8.3-15.2-17.7c-0.3-2.5-1.2-4-3.3-5.7
		c-13.7-10.7-14.8-30.7-2.2-42.6c3.1-2.9,5.1-5.9,6.2-10.1c4.6-18.1,17.7-25.6,35.6-20.3c3.3,1,5.4,0.2,8.1-1.2
		c12-6.5,17.7-6,27.6,2.9c2.4,2.1,4.2,2.1,6.9,1.8c19.5-2.1,30.6,9.6,26.9,28.8c-0.7,3.4,1,4.7,2.6,6.7c8.4,10.2,8.4,16.3-0.3,26
		c-2.5,2.8-3.8,5.6-3.6,9.4c0.5,8.2-2.2,15-10.1,18.7c-8.7,4.1-17.4,4.6-25.7-1c-1.9-1.3-3.5-3.2-6.1-3c-3.3,1-5.1-0.8-6.3-3.5
		c-0.5-2.6,1.1-5.5-1.3-7.7c-1.4-0.5-2.5-1.5-4.1-1.6c-3-0.6-4.7,1-5.7,3.6C-552.5-294.8-556.8-296.7-557.8-292.9z"/>
	<path d="M-238.9-688.3c-1.7,1.6-3.9,1.8-5.7,1.1c-6.4-2.6-12.4-5.6-19.8-3.4c-2.3,0.7-5.6-1.4-6.3-3.9c-1.9-7.1-6.8-10.9-12.2-15.3
		c-6.5-5.3-5-16.5,2.5-23c3.9-3.4,3.6-5,0.7-8.8c-8.8-11.5-5-21.4,9.1-23.9c4.1-0.8,4.1-2.1,3.8-5.6c-1.4-14.8,9-23.7,23.5-20
		c3.5,0.9,5.7,0.4,8.7-1.5c12.4-8.1,20.8-5.7,27.1,7.8c1.9,4.1,4.1,3.6,7.5,3.3c13.8-1.4,23.2,9.6,18.9,22.6
		c-1.7,5.1-1.9,7.9,3.7,9.7c3.2,1,4.9,4,6.7,6.6c0.7,3.5,2.8,7-0.2,10.4c-1,1.2-2,2.5-3.1,3.7c-1.6,0.7-3.6,1-3.2,3.6
		c-2.8,2.6-1.3,4.8,0.1,7.6c5,9.9-1.2,21.1-12.3,22.4c-2.8,0.3-6.1-1.1-8.4,1.3c-7,7.4-14.1,8.3-23.4,3.3c-6.1-3.2-1.9,4-3.8,5.5
		c-4,2.6-7.3,2.4-9.6-2.3C-235.4-689.4-236.8-690.4-238.9-688.3z"/>
	<path d="M-514-700.9c-6.3,10.8-9.5,11.7-26.1,7.1c0,13.7-0.1,27.3,0.1,40.9c0.1,4.4-0.2,7-5.8,6.9c-5.6,0-5.8-2.5-5.7-7
		c0.3-14.2,0.1-28.5,0.1-43.2c-5.3,2.6-10.5,3.6-16.2,0.2c-3.1-1.9-5.9,1.5-8.6,3c-5.3,3.1-10,2.6-14-2.2c-1.3-1.5-2.2-4.1-4.4-3.8
		c-9.4,1.3-11.4-5.5-13-12.1c-1.8-7.4-3.4-15.2,3.7-21c3.4-2.8,3.9-6.1,3.9-10.1c-0.1-9.4,3.3-16.6,13.5-18.5
		c3.5-0.6,3.6-3.1,4.2-5.7c2.5-10.3,8.1-14.2,18.5-11.9c3.6,0.8,5.8,0.7,8.1-2.5c1.2-1.8,3.6-2.9,5.6-3.9c8.4-4.1,14.6,5.4,23.3,3.5
		c9.6-2,12.3,7.3,17.8,13.2c7.3,7.8,9.5,18.6,18.5,25.1c11.9,8.7,11.2,28.2,0.1,37.4c-4.6,3.8-6.1,11.8-14.6,9.6
		C-507.7-698.2-508.9-702.9-514-700.9z"/>
	<path d="M89.6-695c0.3,15.4,0.8,29.4,3.2,43.1c0.9,5.3-0.8,5.7-5.1,5.8c-12.1,0.3-11.8,0.5-10.5-11.1c1.3-11.8,1.9-23.8,2.9-37.2
		c-10.1,8.5-20.6,4.7-28,0.7c-9.2-4.9-17.7-13-22.7-23.1c-1.7-3.3-2.1-8.9,0.4-10.9c10.2-8.4,2.3-24.1,13.4-31.5
		c4.2-2.8,6.5-5.6,7.3-10.8c1.1-6.8,10.3-12.3,19.4-12.1c3.8,0.1,6.5-0.6,9.5-2.8c8.1-6,17.3-4.6,24,3.3c2.3,2.7,4.1,5,8.2,5.7
		c9.9,1.7,17.8,11.5,18.3,21.7c0.1,1.4-0.8,3.5-0.2,4.2c9.4,10.1,7.9,21,3,32.4c-0.5,1.1,0.6,2.8,0.9,4.2c3,14.4-6.4,23.4-20.5,19.3
		c-2.4-0.7-4.3-1.6-6.5,0.3C101.4-689.1,96.1-690.2,89.6-695z"/>
	<path d="M-501.8-524.3c-4.2,1.6-11,2.6-11.4,6c-0.7,4.8,6.1,6.7,9.5,10.1c2.9,2.9,10.2,2,9.2,7.1c-0.9,4.6-7.4,4.7-11.5,6.6
		c-1,0.4-2,0.8-3.7,1.4c3.8,4,8.2,5,12.7,6.1c1.9,0.4,4.4,0.4,4.7,2.9c0.3,2.4-2,3.3-3.7,4.4c-8.8,5.5-18.4,3.7-27.9,2.9
		c-12.4-0.8-12.9-0.3-11.2,12.5c0.4,1.7,0.6,3.5,1.3,5c4.7,9.4,4.3,10.4-6.2,11.8c-3.2,0.4-6.4,0.5-9.6,0.2
		c-11.3-1.1-11.9-2.3-7.3-12.4c1.1-2.3,1.8-5,2.2-7.5c1.7-12.2,0-13.5-11.7-10.4c-9.8,2.6-19.6,3.1-28.8-2.3
		c-1.8-1.1-3.9-2.3-3.4-4.6c0.3-1.8,2.5-2.1,4.3-2.4c4.6-0.8,8.9-2.2,13.2-5.9c-4.3-1.7-7.9-3.2-11.5-4.7c-1.9-0.8-4.1-1.8-4.3-4
		c-0.2-2.6,2.4-2.8,4.2-3.6c5.1-2.4,9.4-5.8,13.5-9.6c3.1-2.8,3.1-4.5-1.3-5.9c-4.3-1.3-10.7-1.7-11.1-6.7c-0.3-4,5.9-5.7,9.2-8.4
		c5.6-4.4,11.6-8.5,16.4-14.3c-13.9-5.5-13.9-5.5-4.7-17c7.5-9.4,13.2-19.9,19.7-30c5.2-8.1,6-8.2,11-0.3
		c7.8,12.3,14.9,25.1,24.7,36c4,4.4,2.4,7.2-2.8,8.7c-1.9,0.5-3.8,1-6.9,1.8c5.8,7.8,13.5,12.4,19.9,18.3
		C-504.3-529.7-500.6-528.1-501.8-524.3z"/>
	<path d="M-6-744.5c1.5,3.9-1.6,6.9-2,10.4c-5.1-0.1-8.5,6.6-14.3,3.8c-6.1-2.4-8.7,4-13.3,5.3c-6.2,5.2-11.9,1.5-18.1-1.3
		c-2.3,6,4.7,8.5,4.2,13.4c-0.2,2.6,0.6,6.1-4,5.3c-4.3-1-6,1.5-6.8,5.1c-1.6,3.5-4.2,4.8-7.6,2.6c-3.9-2.6-6.9-1.3-9.9,1.5
		c-5.8,6.2-10.1,1.3-14.5-2.1c-6.3-0.8-12.8-0.2-17.5-6c-2.1-2.5-5.5-0.7-8.3-0.2c-6.3,1.1-11-0.7-12.3-7.7c-0.1-2.5-1.4-5,0.1-7.6
		c0-0.3-0.1-0.8,0-0.9c7.5-5.3,10.6-14.4,17.6-20c3.5-3.4,7.3-3.9,12-2.4c7.5,2.4,16.6-0.7,21.6,5.3c-6.8-3.5-14.9-7.9-24.1-8.9
		c-6-0.7-10.5-2.7-12.7-8.6c-2.9-4.8-2.1-10.6-5-15.5c-2.1-3.6-0.1-7.3,3.5-9.7c2.7-1.7,5.8-3,8-5.2c4.9-4.9,12.5,0.7,15.9-3.1
		c3.8-4.3,5.9-9.1,12.9-7.7c1.4,0.3,3.2-1,4.7-1.9c1.3-0.7,2.2-1.9,3.5-2.6c4.7-2.5,24.9,1,28.9,4.9c1.7,1.7,3.2,3.7,5.7,3.8
		c7.8,0.3,13.3,3.4,15.3,11.6c0.7,3,4,2.8,6.5,3.6c7.9,2.7,12,8.9,8.4,15.3c-3,5.5-2.1,9.5,0.4,14.2C-7.1-748-7.1-746.1-6-744.5z"/>
	<path d="M221.8-683.3c-9.8,9.1-8.4,19.5-3.9,30.5c0.8,1.9,3.8,5,1.2,5.9c-3.9,1.4-8.6,0.7-12.9,0.7c-2.8,0-3.1-1.8-3.1-4.1
		c-0.2-10.5-0.8-21,5.3-30.4c3-4.6,3.4-9.8,0.6-15c-2.8,1.7-5.3,3.6-8.2,4.9c-3.3,1.5-6.9,1.7-10.3,0.4
		c-9.2-3.4-16.4-27.8-10.1-35.2c2.7-3.1,2.6-5.6,1.9-9.3c-1.2-7.4,1-13.7,9.3-16.1c2.6-0.8,4.8-1.6,6.1-5.1
		c2.7-6.9,8.6-11,16.2-11.1c4.5-0.1,7.4-1.7,10.2-5.2c5.8-7.3,11.5-8.1,19.6-2.7c3,2,5.5,1.2,8.3,1c8.7-0.9,14.9,2.5,17.8,10.8
		c1.2,3.6,3.6,5.3,6.6,7.4c7.3,4.9,12.6,11.4,10.4,21.1c-0.6,2.5-0.3,4.6,1.7,6.3c0.7,0.6,1.2,1.3,1.8,1.9c2.1,2.6,4.6,6.5,2.8,8.7
		c-7.4,9.3,1.6,21.5-6,30.3c-2.7,3.1-6.6,3.8-10.5,3.1c-4.8-0.8-9.3,0-13.8,1.2c-2.3-0.6-4.8-0.7-6-3.2l0,0
		c-1.8-0.6-2.8-1.9-3.3-3.7l0,0c-1.8-1.5-3.5-3.1-5.1-4.9c-0.7-1.2-1.4-2.4-2.6-3.2c-0.3-0.1-0.7-0.2-1-0.2
		c-2.6,0.4-5.1,0.3-7.2-1.7c-2.2-4-6.1-1.4-9.1-2.2c-1.8-0.4-3.6-0.2-5.4,0.1c-3.6,1.1-5.8,3-4.7,7.2
		C220.4-691.6,223.8-688.2,221.8-683.3z"/>
	<path d="M-105.9-324.6c13.7-21.1,26.3-40.5,39.8-61.3c13.6,20.9,26.1,40.3,39.7,61.3c-7.1-1.4-12.1-2.4-19.2-3.9
		c8.8,13.1,17.3,23.9,24.5,37c-5.4-1.3-9.5-2.2-15.8-3.8c10.1,13.5,19.1,25.3,28,37.2c-0.4,0.7-0.7,1.4-1.1,2.1
		c-10.6-2.3-21.2-4.5-31.8-6.9c-12-2.7-12-2.7-11,9.4c1.4,16.8,1.4,16.8-15.5,16.8c-2.6,0-5.3-0.1-7.9,0c-3.4,0.1-4.6-1.5-4.2-4.8
		c0.6-5.8,0.8-11.6,1.4-17.4c0.5-4.6-0.6-6.3-5.7-5c-12.7,3-25.5,5.5-40.5,8.7c10.6-14,19.7-26.2,29.3-38.8c-5.6-1-9.3,2.3-14.6,2.2
		c5.2-10.1,11.7-18.7,17.7-27.7c1.4-2,4.7-3.9,3.4-6.4c-1.8-3.7-5.2-0.5-7.8-0.3C-99.3-326.2-101.3-325.6-105.9-324.6z"/>
	<path d="M269.5-501.2c-0.6,8.7,5,13.8,18.5,16.4c-1.2,5.2-5.5,7.2-9.5,9.1c-6.7,3.2-14.1,5-21.3,3.6c-9.3-1.9-10.6,2.1-8.8,9.4
		c3.3,13.7,3.1,15.4-13.7,15.4c-2.6,0-5.2-0.1-7.9-0.2c-5-0.3-7.5-2.5-6.5-7.9c1.1-5.7,3.8-12.6,0.9-17c-3.2-4.9-10.7-1.4-16.3-2.2
		c-7.1-1.1-13.5-3.6-19.3-7.5c-3.9-2.6-3.8-4.8,0.9-6.4c5.7-1.9,11.7-3.4,17-8.6c-8.6-2.9-16.4-6-20.3-14.3c-2.2-4.6-2.8-7.7,4-7.5
		c3.8,0.1,7.5-1,10.9-2.7c8.7-4.4,9-6.5,1.9-13.2c-8.2-7.6-8-8.9,1.8-14.5c12.3-7.1,21.7-16.6,25.4-30.8c0.8-3,1.6-6.1,5-8.8
		c3.9,6.5,7.4,12.9,11.3,18.9c6.7,10.1,14.4,19.2,27.2,21.8c4.5,0.9,5.1,3.5,2.9,6.9c-1.7,2.6-4.1,4.9-6.1,7.3
		c-0.8,2.7-4.1,4.9-1.9,8.3c3.5,4.1,8.5,5.9,13.3,7.3c5.9,1.8,5.4,4.6,2.6,8.6C280-503.5,273.9-503.6,269.5-501.2z"/>
	<path d="M-77.5-448.9c-2.8-7.1,0-14.2,0.3-21.2c0.3-6.8-0.1-9.5-8.3-7.4c-16.1,4.1-34.4-7.6-37.5-23.5c7.5,1.5,14.9,3.3,22.3,0.3
		c3.3-1.3,6.4-2.9,8.8-5.6c1.5-1.7,4-3.3,3-5.8c-0.9-2.2-3.6-1.1-5.5-1.4c-9.7-1.6-17.7-5.8-22.7-14.7c-1.1-2-2.9-4-1.2-6.4
		c1.6-2.4,3.7-0.3,5.6-0.1c10.2,1.3,19.1-1.8,26.4-8.9c4-3.9,4.7-7.2-1.9-9.3c-6.7-2.1-11.6-6.6-15.5-12.3c-2.3-3.5-3.5-6.7,2.8-6.4
		c17.5,0.9,28.4-6.9,32.3-25.1c1.4,1.8,2.5,2.7,2.8,3.9c4.5,15.6,15.3,22,31,21.2c5.6-0.3,6.2,2.1,3.4,6.1
		c-3.5,5.1-7.9,9.3-13.7,11.8c-8.6,3.7-8.8,6-1.6,12.2c1.3,1.1,2.7,2.2,4.1,3.3c2.4,2.7,5.8,2.6,8.9,3.5c0.6-0.2,1.1-0.1,1.6,0.2
		c2.4,1.4,5.1,0.4,7.6,0.8c2.6,0.4,6.1-0.3,6.3,4c-4.2,10.8-13.2,14.9-23.8,16c-6.1,0.6-5.5,3-2.5,6.3c6.6,7.3,15.2,9,24.6,7.8
		c2.5-0.3,4.7-2.6,8.1-0.6c-4,12.8-14,18.9-25.6,23.4c-4.1,1.7-8.2,1-12.3-0.1c-6.5-3.6-9.2-2.2-8.7,5.7c0.4,7.4,3.8,14.9,0.2,22.4
		C-64.7-447.6-71.1-447.3-77.5-448.9z"/>
	<path d="M43.1-501.2c-2-2.4-7.1-2.6-6.6-6.2c0.5-3.9,5.3-3.9,8.4-5.3c6.8-3.1,14.4-4.7,21.3-10.7c-5.9-1.8-11-3.1-15.9-5
		c-2-0.7-4.8-1.5-5-4.2c-0.2-3,2.6-3.3,4.6-4.4c4.8-2.6,9.5-5.5,14.3-8.3c-0.7-1.1-0.8-1.7-1.2-1.9c-13.5-6.7-13.5-6.6-1-15.3
		c1.9-1.3,3.6-2.8,5.5-4.4c-9.9-5.6-9.8-5.6-1.9-13.2c2.7-2.6,5.1-5.6,7.6-8.4c0.6-1.6,1.3-3.1,3-4c3-3.3,5.5-1.9,8.1,0.6
		c3.9,3.8,7.7,7.7,11.8,11.3c6.8,6.1,6.9,6-1.3,11.4c6.6,4.8,13.2,9.6,19.9,14.4c-1.7,2.9-4.8,3.6-7.7,4.3c-2.2,0.5-4.1,1.6-3.9,3.8
		c0.2,2.1,2.2,3.2,4.4,3.5c2,0.1,3.5,0.9,4.2,2.9c2.7,3.1,9.5,3.2,9.1,7.1c-0.5,4.8-6.9,5.4-11.1,6.9c-4.7,1.7-3.9,3.5-0.8,5.8
		c5.1,3.9,10.8,6.9,16.6,9.6c2.3,1.1,5.5,1.9,5.3,5c-0.2,3-3.4,3.7-5.8,4.6c-4.8,1.8-9.7,3.5-15.1,5.5c7.1,5.4,14.9,8,22.2,11.5
		c2.3,1.1,6.1,1.2,6.1,4.1c0,3.2-3.8,3.9-6.4,5c-9.2,3.9-18.8,4.6-28.3,2.1c-8-2.1-9,1.2-7.5,7.7c0.1,0.6,0.3,1.1,0.3,1.7
		c0.2,4.9,6,10.8,0.9,14.5c-5.8,4.1-13.6,1.8-20.5,1c-3.1-0.4-3.1-2.6-2.5-5.3c1.4-6.6,4-14.6,0.1-19.5c-3.9-5-11.9-0.2-18.2-0.8
		c-8.5-0.9-17.8,0.5-23.5-8.2c1.7-4,5.6-4.3,9.1-5.2c0.5,0.1,1,0,1.3-0.4c2.6-3.6,5.9-6.3,9.7-7.9C49.3-496.4,45.4-497.4,43.1-501.2
		z"/>
	<path d="M218.4-363.2c-1.3-0.5-3.1,0.2-3.8-1.7c-1.4-2.6-0.3-4.2,2-5.3c5.8-4.1,9.8-9.9,14.3-15.2c2.9-3.5,4.7-4.1,7.8-0.3
		c3.4,4.3,7.1,8.5,11.1,12.3c5.3,4.9,8.1,8.9-2.5,10.9c4.8,8.3,12.9,11.6,19,16.7c0.4,2-0.8,2.5-1.7,2.9
		c-10.5,4.8-10.7,5.5-3.5,15.1c4,5.4,8.5,10.5,14.2,14c4.8,3,4.6,4.9-0.2,7.6c-10.5,6-10.4,6.1-3.5,16.1c2.8,4.1,5.6,8.2,8.9,11.9
		c5.1,5.8,2.9,8.3-3.6,9.6c-9.1,1.8-18.2,4.2-27.3,5.7c-6.3,1-8.9,3.5-7,9.8c0.6,1.9,0.6,4,1,6c2,10.1,2,10.1-8.3,10.1
		c-11.8,0-12.1-0.4-7.9-12.6c0.8-4.1,1.8-8.1-0.3-12.1c-11.1-2.3-22.3-4.3-33.3-6.9c-9-2.1-9-2.7-3.4-10.6
		c3.9-5.4,7.8-10.9,11.6-16.4c3-4.4,3.4-7.7-3.3-9.1c-3-0.6-6-2.3-8.1-5.7c7.5-5.4,14.4-11.5,19.7-19.2c5.8-8.4,5.7-8.9-3.5-12.8
		c-1.2-0.5-2.3-1.5-3.8-2.5c2.6-2.2,4.8-4,6.9-5.9c0.4-3,3.5-3.7,5.1-5.5C216.5-358.1,220.1-359.3,218.4-363.2z"/>
	<path d="M391.5-448.9c-6.7,3.4-13.7,1.1-20.6,1.5c-4.2,0.2-4.2-3.2-3.8-6.3c0.7-5.5,1.6-10.9,2.1-16.4c0.4-5-2-7.1-7-5.4
		c-4.7,1.6-9.3,3.5-14.1,4.7c-4.6,1.2-10,6.6-13.6,2.2c-4.1-5,2.8-9.1,5.1-13.5c2.7-5.1,6.5-9.6,9.7-14.5c1.2-1.8,4-3.3,2.4-5.9
		c-1.2-1.9-3.6-1.4-5.7-1.3c-3.3,0.1-7.8,2.2-9.5-1.8c-1.5-3.5,2.4-6.2,4.4-8.9c5.2-7,10.8-13.7,16.1-20.7c1.4-1.9,5.4-4.5-0.3-6.3
		c-2.2,1.1-5-0.2-7.1,1.5c-4.2,1-6-0.3-3.6-4.4c9.4-16.1,18.4-32.5,29.1-47.8c3-4.3,5.2-3.4,7.9,0.3c10.6,15.1,19.3,31.2,28.7,47
		c2.7,4.6,0.3,5.5-4,5.1c-2.8-0.3-5.6-2-9.1-0.4c1.1,4.7,4.7,7.8,7.4,11.4c4,5.4,8.3,10.5,12.3,15.9c1.6,2.2,4.7,4.5,2.9,7.6
		c-1.8,3.1-5.2,1.6-8.1,1.5c-2.6-0.1-5.8-1.2-7.1,1.7c-1,2.3,1.8,4,3.1,5.9c4.7,7,9.4,14.1,14,21.2c1.2,1.9,2.9,4.1,0.9,6.4
		c-1.8,2.1-4.2,1.3-6.5,0.5c-6.9-2.4-13.8-4.8-20.6-7.3c-6.5-2.3-9,0.4-8.2,6.6c0.8,6.1,2.1,12.1,3.1,18.1c0.5,0.5,0.6,0.9,0.4,1.2
		C392-449.1,391.8-448.9,391.5-448.9z"/>
	<path d="M-352.4-519c0.5-0.1,1,0.1,1.3,0.6c1.5,2.1,6.4,3.2,1.9,7c-4.2,2.3-8.5,4.7-13.2,7.3c3.2,5,7.7,7.2,13.1,8.1
		c1,2.2,5.3-1.1,5.2,3.1c-12.6,12.4-26.5,12.1-41.6,3.3c-0.9,12.2,0.6,23.1,3.1,33.8c1.5,6.3-0.5,8.8-6.7,8.4
		c-0.9,0-1.7,0.1-2.6,0.1c-16.9,0.5-17.4,0-14.9-16.1c1.1-7.5,1.8-15,1.3-24.2c-14.6,9.6-28.4,8.2-43.1-1.2
		c5.1-3.2,9.8-4.1,13.2-7.3c2.5-2.4,3.9-4.4-0.3-6.4c-1.6-0.8-3-1.7-4.4-2.8c-6.9-5.4-6.8-6.4,0.6-11.4c1.9-1.3,3.8-2.7,5.5-4.3
		c4.4-4.1,7.2-8.1-1.3-10.8c-1.3-0.4-2.5-1.4-3.6-2.3c-6.5-5.3-6.3-7.3,1.4-10.4c5.9-2.4,11.9-4.7,16.5-9.4c1.2-1,1.9-2.7,3.6-3.2
		c8-6,12.2-14.5,15.7-23.6c0.8-2,0.9-5.3,3.7-5.2c2.5,0,3,3.2,4.2,5.1c4.3,6.9,8.5,13.8,13.1,20.6c4.8,7.2,11.5,12.1,19.8,15
		c11.1,3.8,11.7,6.8,2,14.2c-4,3-3,4.5-0.5,7C-358.6-520.6-354.9-520.7-352.4-519z"/>
	<path d="M361.8-349.9L361.8-349.9c-0.9-3.3-1.2-6.6,0.1-9.9c2.8-9.3,5.3-18.7,8.3-28c1.3-3.9,4.4-5.9,8.8-5.6
		c4.7,0.4,7.4,2.8,8.6,7.2c1.8,6.6,3.8,13.1,5.7,19.7c-1,4.1-1.3,8.2,0.1,12.2c6.6,10.2,11.7,20.8,8.7,33.5c-0.8,3.5,0.9,6.8,2.3,10
		c3.8,8.3,5.7,17.1,4.7,26.3c-1,9-4.8,16.6-14.3,18.7c-6.3,1.4-7.2,4.7-6.2,10c0.6,3.1,1.3,6.3,1.6,9.5c0.3,2.9,3.6,6.6-0.6,8.5
		c-4.3,2-9.2,1.9-13.7,0c-4.1-1.7-1.9-5.5-1.8-8.5c0.1-3.5,0.7-7,0.7-10.4c0.1-4.5-1.8-7.3-6.5-8.9c-19.6-6.6-25.2-21.3-15.1-39.5
		c2.3-4.2,2.4-8.2,1.1-12.6c-2.1-7.2-2.2-14.3,2.3-20.8C359.6-341.9,361.7-345.5,361.8-349.9z"/>
	<path d="M-252.6-341c-1.4-5-3.2-9.9-4.2-15c-3.2-16.6,5.3-28.2,22.9-31.7c17.4-3.4,30.2,4.3,33.6,20.4c3.7,17.5-3.5,31.1-15.9,42.7
		c-3.6,7.1-7.8,13.9-6.1,22.4c0.6,3.1,0.6,6.3,3,8.7c1.4,1.7,4.4,2.5,3.1,5.6c-5.9,6.2-7.3,13.6-6.2,21.8c0.9,6.3,1.5,12.7,2.4,19
		c1.4,9.9,0.8,10.3-9,10.4c-5.5,0.1-7.1-2-6.5-7.3c1.7-13.6,1.1-27.1-3.6-40.2c-0.9-3.5-0.5-6.7,1.5-9.7
		c3.2-13.4,1.3-25.2-10.2-34.3C-251.7-331.4-253.9-335.7-252.6-341z"/>
	<path d="M-216.2-287.9c-1.9-0.6-3.8-1.3-5.7-1.9c-0.1-6.2,0.8-12.4,0.1-18.7c-0.9-8.1,5.2-12,10.7-16.1c1.3-1.2,2.6-2.4,3.9-3.5
		c10.9-9.9,21.6-12.6,30.9-7.8c10.3,5.4,19.1,21.5,17.9,33c-0.7,6.7-4.5,11.5-10.1,14.5c-13,7.2-26.5,9.7-39.3-0.1
		C-211.6-291.5-213.5-289-216.2-287.9z"/>
	<path d="M-256.5-287.9c-2.1,0.7-4.2,1.3-6.3,2c-3.9,1-7.8,1.1-11.7,0c-23.2-4.5-32.6-28.9-18.3-47.6c5.3-7,12.6-7.4,20.4-6.8
		c3.1,0.9,6.5,0.9,9,3.3l0.1,0c10.9,0.4,16.2,8.8,22,16c5.2,6.5,5,13.8,1,21C-245.4-295.5-253.2-294.7-256.5-287.9z"/>
	<path fill="#020202" d="M-53.6-707.6c1.4-1.8,1.1-3.8,0.5-5.9c6.8-10.5,8.8-11.3,20.9-8.5c4.1,2.8,7.2,6.7,13.4,6.6
		c5.2-0.1,8.4,8.8,5.7,13.7c-1.3,2.3-2.1,4.3-2.3,7.2c-0.3,6.3-9,12-14.7,9.3c-4-1.9-8-2.9-12.1-4.1c-6.1-1.8-10.4-5.8-8.8-12.8
		C-50.2-705.3-51.7-706.2-53.6-707.6z"/>
	<path fill="#040404" d="M-545.1-303.6c5.6-2.3,6.3-2.2,8.2,1.8c-2.6,2.8-0.4,5.6,0.1,8.4c1.2,1.9,2.5,3.7,3.7,5.6
		c-3.8,3.2-5.3,7.3-5.7,12.2c-1.6,17.5-0.5,25.7,5.7,36.8c-7.5,1.4-14.7,2.8-22.7,1.4c8.4-16.2,5.3-33.3,4.6-50.1
		c-0.1-2.8,1-10-6.4-5.5c-0.4-3.9,3.3-4.1,5.3-5.7C-549.4-299.4-548.5-303.4-545.1-303.6z"/>
	<path fill="#030303" d="M-79.5-702.5c6.4-3.7,12.7,1.2,19.1,0c0.8,17.4,2,34.7,5.7,51.7c0.8,3.6,0,4.3-3.5,4.6
		c-14.8,1.4-14.7,1.4-13.9-13.2c0.7-11.8,0.8-23.7,1.1-35.6c0-1.8,1.5-5.4-3-3.9C-76.6-699.1-80-698-79.5-702.5z"/>
	<path fill="#060606" d="M-238.9-688.3c6.2-5.1,9.2,3.1,14.1,3.4c1.1,11.6,0.6,23.3,4.1,34.6c1,3.4-0.6,3.8-3.3,4.2
		c-11.2,1.7-12.8,0.1-11.9-11c0.6-8.4,0.8-16.8,1-25.3C-235-685.1-234-688.8-238.9-688.3z"/>
	<path fill="#F7F7F7" d="M-116-757.4c0.7,0.5,1.3,1.4,2.1,1.5c13.1,1.5,24.7,8.2,37.4,10.5c5.6,1,8.2,2.4,4.5,7.2
		c-3.7,4.7,1.2,8.9-0.2,13.4c-4.9-1.1-5.8-4.9-6.9-8.4c-1.3-3.9-4.5-8.3-7.7-7.1c-9.4,3.6-17.1-3.4-25.9-2.6
		C-112.4-748.1-115.9-752.4-116-757.4z"/>
	<path fill="#F6F6F6" d="M-243.6-301.6c5.2-11.2-0.2-19.4-8-26.5c-3.6-3.3-7.8-5.8-11.7-8.7c4-0.2,7.4-2,10.7-4.1
		c4.9,7.4,9,15.6,18,19.2c2.8,1.1,2.6,3.5,2.6,5.8c0,8.4,0.4,16.8-1.6,25c-4.1,1.6-7.1,0.9-10.1-2.8
		C-246.1-296.8-246.4-299-243.6-301.6z"/>
	<path fill="#090909" d="M238.9-702.7c4,2.4,4.8-2.8,7.6-3.3c1.3,0.9,2.6,1.7,3.3,3.2c0.6,1.9,1.2,3.8,1.9,5.6
		c-2.7,3.8-6.7,3.4-10.7,3.4c-0.6-0.1-1.1,0-1.5,0.5c-3.4,4.9-8.2,7.5-13.9,8.4c-1.2,0.5-2.5,1.1-3.7,1.6c-4.9-2.7-6.5-7.1-6.9-12.3
		c4.6-2.7,4.8-8.3,8.2-11.8c1.9,0.5,3.7,1,5.6,1.5C231.5-703.2,235.4-703.7,238.9-702.7z"/>
	<path fill="#F6F6F6" d="M-211.1-324.6c-5.1,8.1-8.6,16.3-4.6,26.1c2.4,5.8-4.7,5.4-6.3,8.8c-3.3,0.2-3.6-2.2-3.8-4.6
		c-0.4-4.6-0.1-9.3-1-13.8c-1.8-9.1,1.2-14.7,10.5-16.5C-214.5-324.6-212.8-324.6-211.1-324.6z"/>
	<path fill="#F6F6F6" d="M-32.2-722c-5.9,0.8-12.2,0.2-14.5,8c-1.3,4.4-4.2,0-6.4,0.5c-5.8-4-7.2-10.1-8-16.5
		c-0.4-2.8-0.1-5.4,2.5-6.7c3-1.5,4,1.6,5.2,3.3c4.4,6,10,9.4,17.7,8.5C-34.5-723.9-33.4-723-32.2-722z"/>
	<path fill="#0B0B0B" d="M-243.6-301.6c-1,7.6,3.6,9.9,10,10.6c-1.8,2.3-3.6,4.6-5.4,6.9c-4.4-7.7-10.1-9.8-17.4-3.7
		C-257.3-297.2-247.7-296.9-243.6-301.6z"/>
	<path fill="#F5F5F5" d="M43.1-501.2c6,1.4,12.1,2.7,20.2,4.6c-7.8,3.5-14,6.3-20.2,9C44.7-492.1,44.3-496.6,43.1-501.2z"/>
	<path fill="#070707" d="M-79.5-702.5c1.8,1.2,3.6,2.5,5.4,3.7c-7.6,10.3-11.6,10-18.2-1.8C-87.8-699.3-83.1-697.2-79.5-702.5z"/>
	<path fill="#F8F8F8" d="M-190.3-536.4c-0.1-4.3,8.2-3.4,5.7-9.1c4.1-2.8,7.4,0.3,8.3,3c1.3,3.6-3.6,3.7-5.6,5.4
		C-184.3-535.1-187.5-537.3-190.3-536.4z"/>
	<path fill="#F9F9F9" d="M225.5-685c3.9-4.1,8.7-6.6,13.9-8.4C241-684.7,235.7-681.4,225.5-685z"/>
	<path fill="#060606" d="M-435.6-781.4c7.8,7.7,7.8,7.7-1.1,15.9C-437.3-770.9-438.5-776.3-435.6-781.4z"/>
	<path fill="#0A0A0A" d="M-270.4-548.2c-0.2,4.4-2,8.3-3.7,12.2c-2.9-1.7-7.5-3.5-6.4-6.9C-279.3-546.5-274-546.7-270.4-548.2z"/>
	<path fill="#F2F2F2" d="M-535.2-464.5c-0.4-1.3-1.1-2.7-1.2-4c-1-12.8,0.7-13.9,12.4-8.4C-532.2-476.8-534.6-471.4-535.2-464.5z"/>
	<path fill="#F3F3F3" d="M269.5-501.2c2.8-4.5,8.2-5.4,12-8.7C282.1-501.6,279.4-499.7,269.5-501.2z"/>
	<path fill="#080808" d="M-22.3-730.3c2.9-0.1,4.2-1.9,5.8-4.2c2.6-3.6,5.9-4.4,8.5,0.3C-11.7-728.8-16.3-726.8-22.3-730.3z"/>
	<path fill="#F6F6F6" d="M218.4-363.2c4.3-0.3,4.6,1.5,2,4.2c-3.1,3.3-6.3,6.6-10.7,8.3C207.7-358.2,217.3-357.7,218.4-363.2z"/>
	<path fill="#F9F9F9" d="M241-693.8c3.2-2.1,6.5-4.1,10.7-3.4c0.6,2.3,1.3,4.6,1.9,6.9C249.2-690.9,244.6-690.7,241-693.8z"/>
	<path fill="#F5F5F5" d="M-403.5-647c4.9,0.2,9.8,0.4,14.7,0.7c2.8,0.2,4.4-0.9,4.5-3.8c2.4,1.4,2.6,3.9,0.1,4.2
		C-390.6-644.9-397.4-642-403.5-647z"/>
	<path fill="#F8F8F8" d="M-170.8-732.3c0.1-3.5,0.2-6.9,0.2-10.4c1.4,1.9,2.7,3.8,4.1,5.5c1.3,1.6,2.8,3.4,1.2,5.3
		C-167.1-729.8-169-732.2-170.8-732.3z"/>
	<path fill="#050505" d="M-177.1-725.1c-2.5-1-7.2-1.7-5-5.2c2.3-3.7,5.5,0.5,8.2,1.6C-174.3-726.8-175.4-725.7-177.1-725.1z"/>
	<path fill="#080808" d="M337.3-751.9c-0.9,6-5.2,8.2-10.5,9.1C329-747.4,332.2-750.7,337.3-751.9z"/>
	<path fill="#0D0D0D" d="M-18.3-529.6c-3.9-3.8-9.6-2.2-13.9-4.8c3.7-0.3,7.5-0.4,11.2-0.8C-14.6-536-15.4-533.3-18.3-529.6z"/>
	<path fill="#F7F7F7" d="M-417.6-557.3c-0.7,1.6-1.9,2.7-3.6,3.2c-0.9-3.7-4-7-2.1-11.7C-419.5-564.1-421.3-558.7-417.6-557.3z"/>
	<path fill="#0B0B0B" d="M-501.8-524.3c-3-1.9-4.7-4.4-3.5-8.1C-497.6-528.6-497.6-528.5-501.8-524.3z"/>
	<path fill="#0B0B0B" d="M-514-700.9c5.1-2,9-2,8.8,5.2C-509.5-695.2-510.1-700.9-514-700.9z"/>
	<path fill="#F9F9F9" d="M391.5-448.9c-0.1-0.6,0-1.1,0.3-1.6c2.2-0.7,4.5-1.7,5.7,1.3c0.3,0.8-0.1,2.6-0.7,3
		C394-444.5,393.3-448,391.5-448.9z"/>
	<path fill="#080808" d="M-440.4-707.8c8.7-0.1,2.1,5.9,3.5,8.7C-439-701.6-440.7-704.3-440.4-707.8z"/>
	<path fill="#181818" d="M-77.5-448.9c6.4,0.1,12.9,0.1,19.3,0.2C-64.7-446.4-71.1-446.2-77.5-448.9z"/>
	<path fill="#0E0E0E" d="M-352.4-519c-3-1-10.3,4.2-7.3-5.2C-357.3-522.4-354.9-520.7-352.4-519z"/>
	<path fill="#080808" d="M-440.6-728.7c4.7,3.4,5.7,6.8,0.3,10.4C-440.3-721.8-438.8-725.3-440.6-728.7z"/>
	<path fill="#F3F3F3" d="M-49.7-476.8c4.1,0,8.2,0,12.3,0.1C-41.6-470.9-45.6-476-49.7-476.8z"/>
	<path fill="#F6F6F6" d="M-352.6-340.3c-0.7-2.3-1.4-4.6-2.1-6.9c2.1-0.7,4.2-0.8,5.2,1.5C-348.4-343-350.8-341.8-352.6-340.3z"/>
	<path fill="#F5F5F5" d="M41.8-487.1c-3,1.7-6.1,3.5-9.1,5.2C33.7-487.2,37-488.4,41.8-487.1z"/>
	<path fill="#F6F6F6" d="M216.6-370.1c-0.7,1.8-1.4,3.5-2,5.3c-1.8-0.6-4-1.2-2.9-3.8C212.5-370.8,214.6-370.7,216.6-370.1z"/>
	<path fill="#080808" d="M325.7-732.3c4.3-0.4,7.8-0.1,4.7,5.6C327.8-727.7,326.3-729.7,325.7-732.3z"/>
	<path fill="#080808" d="M-6-744.5c-1.8-0.9-4.5-1.5-5.2-2.9c-0.6-1.1,2.2-1.9,3.8-2.4C-5.8-748.4-5.8-746.5-6-744.5z"/>
	<path fill="#0F0F0F" d="M361.8-349.9c0.6,4.8-0.7,8.8-5.1,11.4C358.4-342.4,360.1-346.2,361.8-349.9z"/>
	<path fill="#F0F0F0" d="M265.7-525.8c-3.6-3.7-2.3-6.3,1.9-8.3C268.7-530.9,266-528.6,265.7-525.8z"/>
	<path fill="#F7F7F7" d="M407.2-324.9c-1.6-3.9-1.6-6-0.6-8.5C408.3-331.2,407.4-329,407.2-324.9z"/>
	<path fill="#0E0E0E" d="M-349.2-511.4c0.8-2.7-1.1-4.7-1.9-7c2.3,0.9,5.6,1.2,6.1,3.9C-344.6-512.4-347.2-511.7-349.2-511.4z"/>
	<path fill="#090909" d="M226.9-261.5c4.5,3.9,1.7,8,0.3,12.1C226.9-253.4,228.7-257.5,226.9-261.5z"/>
	<path fill="#F8F8F8" d="M335.9-774.4c-1.3,0.7-2.1,1.4-3.1,1.6c-0.3,0.1-1.2-1.1-1.2-1.7c0-1.3,1-2,2.3-1.7
		C334.5-776,334.9-775.2,335.9-774.4z"/>
	<path fill="#0D0D0D" d="M-33.8-534.6c-3.9,1.2-7,0.3-8.9-3.5C-39.7-536.9-36.7-535.8-33.8-534.6z"/>
	<path fill="#F7F7F7" d="M-563.2-384.2c0.6,0.7,1.1,1.3,1.5,1.9c-0.5,0.2-1.1,0.6-1.5,0.5c-0.8-0.2-1.4-0.7-2.1-1.1
		C-564.6-383.3-564-383.7-563.2-384.2z"/>
	<path fill="#0F0F0F" d="M393.5-354.3c-2.4-4-2.3-8.1-0.1-12.2C393.4-362.5,393.5-358.4,393.5-354.3z"/>
	<path fill="#0F0F0F" d="M349.7-539.9c1.3-5.3,4.6-1.6,7.1-1.5C354.7-539.9,352.3-539.7,349.7-539.9z"/>
	<path fill="#F6F6F6" d="M-263.4-336.9c-3-1.1-6-2.2-9-3.3C-268.6-341.4-265.5-340.6-263.4-336.9z"/>
	<path fill="#F7F7F7" d="M-77-445.3c0.7,0.3,1.1,0.5,1.6,0.7c-0.6,0.4-1.1,1.2-1.7,1.2c-0.5,0.1-1.1-0.6-1.7-0.9
		C-78.2-444.6-77.6-444.9-77-445.3z"/>
	<path fill="#F9F9F9" d="M253.5-690.4c1.1,1.2,2.2,2.5,3.3,3.7c-1.6,0.4-3.5,1.2-4.5-0.8C252-688.1,253.1-689.4,253.5-690.4z"/>
	<path fill="#141414" d="M332.2-722c0,3.6,0,7.2,0.1,10.8C329.6-714.8,330.2-718.4,332.2-722z"/>
	<path fill="#0B0B0B" d="M-274.5-285.9c3.9,0,7.8,0,11.7,0C-266.7-284-270.6-284-274.5-285.9z"/>
	<path fill="#F7F7F7" d="M435.9-727c0.4,0.4,0.9,0.7,1,1.1c0.1,0.4-0.3,0.9-0.4,1.4c-0.3-0.4-0.9-0.7-1-1.1
		C435.4-726,435.8-726.5,435.9-727z"/>
	<path fill="#F6F6F6" d="M-427.6-477c-0.3-0.3-0.6-0.6-0.9-0.9c0.5-0.1,1-0.4,1.5-0.4c0.3,0,0.6,0.6,0.9,0.9
		C-426.6-477.3-427.1-477.1-427.6-477z"/>
	<path fill="#0F0F0F" d="M-344.2-492.9c-1.7-1-3.4-2.1-5.2-3.1C-347.4-495.3-343.5-498-344.2-492.9z"/>
	<path fill="#F4F4F4" d="M76.1-592.3c-1,1.3-2,2.7-3,4C73.1-590.4,70.9-594.1,76.1-592.3z"/>
	<path fill="#E6E6E6" d="M111.5-540.1c-1.8-0.4-3.3-1.2-4.2-2.9C110-543.8,112.6-544.6,111.5-540.1z"/>
	<path fill="#0F0F0F" d="M361.9-359.8c0,3.3-0.1,6.6-0.1,9.9C360.3-353.3,360.4-356.6,361.9-359.8z"/>
	<path fill="#0F0F0F" d="M-130.4-722c0.6,2.5,1,5-0.1,7.6C-132.3-717-132.3-719.5-130.4-722z"/>
	<path fill="#F6F6F6" d="M-534.2-277.2c0.4-0.4,0.7-0.8,1.1-1c0.1-0.1,0.6,0.6,0.9,0.9c-0.4,0.4-0.7,0.8-1.1,1.1
		C-533.4-276.2-533.9-276.8-534.2-277.2z"/>
	<path fill="#F9F9F9" d="M256.9-686.7c2,1.1,4,2.1,6,3.2C260-682.9,257.3-682.5,256.9-686.7z"/>
	<path fill="#F6F6F6" d="M-356.7-350.1c-2-2.3-1-5.1-1.2-7.7C-355.7-355.5-355.5-352.9-356.7-350.1z"/>
	<path fill="#040404" d="M371.6-675.5c-0.3,3.1-0.2,6.2-4,6c-2.2-0.1-5.3-0.2-4.6-3.6c0.6-3,2.9-5.1,6.1-5.5
		C371.7-678.9,371.2-676.6,371.6-675.5z"/>
	<path fill="#070707" d="M-235.4-452.3c0.5,0.2,1.2,0.5,1.9,0.8c-0.7,0.3-1.4,0.7-2.1,0.8c-0.5,0.1-1.4-0.1-1.5-0.4
		C-237.4-452.3-236.3-452-235.4-452.3z"/>
	<path fill="#050505" d="M-395.2-377.4c0.3,1.9-0.8,2.6-2.4,2.6c-1.7-0.1-2.7-0.9-2.1-2.8c0.4-1.3,0.5-3.2,2.3-3.1
		C-395.7-380.5-395.7-378.7-395.2-377.4z"/>
	<path fill="#E3E3E3" d="M-545.1-303.6c0,5.2-1.3,8.5-7.3,4.9C-552.8-304.3-551.2-307.3-545.1-303.6z"/>
	<path fill="#E1E1E1" d="M-536.9-293.3c-3.4-2.8-5.3-5.6-0.1-8.4C-536.6-299-532.8-296.2-536.9-293.3z"/>
	<path fill="#070707" d="M-63.5-728.4c-0.6,0.2-1.6,0.8-2.6,1.1c-1.2,0.3-1.4-0.7-0.9-1.4c0.6-0.9,1.4-1.5,2.2-2.3
		C-64.7-730.6-64.4-730.2-63.5-728.4z"/>
	<path fill="#E7E7E7" d="M238.9-702.7c-4.4,1.9-7.1,6.8-12.7,7c-1.6-4.2,2.9-6.7,2.5-10.4C233.3-708.5,236.5-706.9,238.9-702.7z"/>
	<path fill="#E1E1E1" d="M223.1-707.5c1.6,5.7-2,9.7-4.8,13.7c-1.8,2.5-2.1-1.3-3.3-1.9C215-701.4,216.9-705.9,223.1-707.5z"/>
	<path fill="#DBDBDB" d="M249.8-702.8c-2.7,0.6-3.9-0.3-3.3-3.2c1.7-0.6,3.3-2.9,5-0.9C252.8-705.3,250.4-704.2,249.8-702.8z"/>
	<path fill="#060606" d="M96-558.3c-1.6-0.2-2.7-0.9-2.6-2.6c0-1.3,1-2,2.3-1.7c1.4,0.3,2.8,1,2.8,2.6
		C98.5-558.6,97.2-558.3,96-558.3z"/>
	<path fill="#060606" d="M72.1-517.3c-0.1,1.1-0.1,2.3-1.5,2.4c-0.4,0-1.3-1-1.3-1.6c0-0.7,0.6-1.5,1.2-1.9
		C71.5-519,71.9-518.2,72.1-517.3z"/>
	<path fill="#090909" d="M239.2-259.2c-0.1,0.5-0.3,1-0.5,1.5c-0.3-0.3-0.8-0.6-0.9-0.9c-0.1-0.5,0.2-1,0.4-1.5
		C238.6-259.8,238.9-259.5,239.2-259.2z"/>
</g>
<path fill="#FFFFFF" d="M356,82.2c0-7.2-2.8-11-9.6-13.1C297.8,54.5,249.2,40,200.7,25.4c-3.4-1-6.8-1-10.2,0
	C142,40,93.4,54.5,44.9,69.1c-6.8,2-9.6,5.9-9.6,13.1c0,53.8,0,107.6,0,161.4c0,9.1,1.5,17.9,4.2,26.6c6.4,20.3,18.5,37,32.8,52.2
	c17.3,18.4,37.2,33.6,58.2,47.5c19.9,13.1,40.7,24.4,62.1,34.7c2,0,4,0,5.9,0c0.2-0.2,0.3-0.4,0.5-0.5c29.6-13.6,57.4-30,83.4-49.6
	c20-15,38.3-31.6,52.8-52.1c12.4-17.6,20.6-36.8,20.6-58.7C356,189.8,356,136,356,82.2z M308.9,299.9c-8.9,10.4-18.8,19.8-29.2,28.4
	c-0.3-11.4-2.1-22.6-8.8-33c-4.9-7.5-5.6-16-1-24.6c4.6,2.8,8.7,5.9,13.3,8.1c5.3,2.5,11.3,2.7,16.8,0.7c15-5.5,26.8-45.5,16.5-57.5
	c-4.4-5.1-4.2-9.1-3.2-15.3c2-12.2-1.6-22.3-15.2-26.3c-4.3-1.3-7.8-2.6-10-8.3c-4.4-11.3-14.1-17.9-26.6-18.2
	c-7.4-0.1-12-2.7-16.7-8.5c-9.5-12-18.8-13.2-32.1-4.4c-4.8,3.2-8.9,2-13.5,1.6c-14.1-1.4-24.4,4-29,17.6c-2,5.9-5.9,8.7-10.9,12.1
	c-11.9,8.1-20.6,18.6-17.1,34.6c0.9,4.1,0.5,7.5-2.8,10.3c-1.1,0.9-2,2-2.9,3.1c-3.5,4.2-7.5,10.6-4.6,14.2
	c12.1,15.2-2.7,35.1,9.8,49.5c4.4,5,10.8,6.2,17.2,5.1c7.8-1.4,15.2,0.1,22.6,1.9c3.7-1,7.8-1.1,9.9-5.2l0.1-0.1
	c3-0.9,4.6-3.1,5.3-6c2.7-2.3,5.3-4.7,7.6-7.3c3.9,1.9,8.4,1.6,12.9,1.6c1-0.2,1.8,0,2.5,0.8c5.5,8,13.3,12.2,22.8,13.7
	c2,0.9,4,1.8,6,2.6l0,0c16,14.8,13.8,31.9,6.4,49.8c-1,2.4-4.1,5.8-3.6,8c-4,2.6-8,5.2-12.1,7.8c-13.4,8.3-27.2,16.1-41.3,23.1
	c-1.7,0.8-3,1.1-4.8,0.2c-32-16.2-62.7-34.6-89.7-58.5c-15.4-13.7-29.4-28.6-38.6-47.4c-4.9-10-7.5-20.6-7.5-31.8
	c0-2.5,0-9.5,0-19.5c0-33.8,0-100.4-0.1-130.8c0-2.5,0.7-3.6,3.1-4.4c44.1-13.1,88.2-26.3,132.2-39.6c1.9-0.6,3.5-0.6,5.4,0
	c44.1,13.2,88.1,26.4,132.2,39.6c2.4,0.7,3.2,1.8,3.1,4.3c-0.1,25.4-0.1,50.7-0.1,76.1c0,22.6-0.6,45.3,0.2,67.9
	C334.6,261.1,324.6,281.5,308.9,299.9z M246.5,259.9c5.8,1.8,9.4,4.9,7.6,11.7c-0.2,0.4-0.4,0.8-0.6,1.1
	C250.6,268.7,247.6,264.6,246.5,259.9z M237.5,259.6c0.4-0.1,0.8-0.2,1.3-0.2c1.8,3.6,4.7,7,2.8,11.7c-7.7-0.3-12.1-6-17.5-9.7
	C227.8,257.6,233.2,260.8,237.5,259.6z"/>
<rect x="409.5" y="57.4" fill="none" width="822.5" height="326"/>
<text transform="matrix(1 0 0 1 409.536 157.433)"><tspan x="0" y="0" fill="#FFFFFF" font-family="'FranklinGothic-Demi'" font-size="150px">ANTI</tspan><tspan x="0" y="180" fill="#FFFFFF" font-family="'FranklinGothic-Demi'" font-size="150px">GRELE.MA</tspan></text>
</svg>

</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useColors } from 'vuestic-ui'

const { getColor } = useColors()

const props = withDefaults(
  defineProps<{
    height?: number
    start?: string
    end?: string
  }>(),
  {
    height: 18,
    start: 'primary',
    end: undefined,
  },
)

const colorsComputed = computed(() => {
  return {
    start: getColor(props.start),
    end: getColor(props.end || props.start),
  }
})
</script>
<style scoped>
.stL0 {
  fill: #76963d;
}
.stL1 {
  fill: #1f160e;
}
.stL2 {
  fill: #77963d;
}
.stL3 {
  fill: #201710;
}
.stL4 {
  fill: #7d9840;
}
.stL5 {
  fill: #201811;
}
.stL6 {
  fill: #7d9740;
}
.stL7 {
  fill: #7a963f;
}
.stL8 {
  fill: #7e9641;
}
.stL9 {
  fill: #7e9741;
}
.stL10 {
  fill: #7c9640;
}
.stL11 {
  fill: #1f160f;
}
.stL12 {
  fill: #77973d;
}
.stL13 {
  fill: #77933c;
}
.stL14 {
  fill: #77943c;
}
.stL15 {
  fill: #7a913b;
}
.stL16 {
  fill: none;
}
.stL17 {
  font-family: 'MyriadPro-Regular';
}
.stL18 {
  font-size: 60px;
}
</style>
